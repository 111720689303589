import { Type4Fraction, Type4FractionType } from "./Type4Fraction";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type4-fraction-table",
    styleUrls: ["./type4Fraction.table.scss"],
    templateUrl: "./type4Fraction.table.html"
})
export class Type4FractionTable extends PagingTableComponent<Type4Fraction> implements OnInit {

    @Input() title: string;

    Type4Fraction = Type4Fraction;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type4FractionType, Type4Fraction.columnInfo, modalTab || tab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type4Fraction.valueListFieldKeys, Type4Fraction.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type4Fraction.valueListArrayKeys, Type4Fraction.getValueListArrayValues);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type4Fraction) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type4Fraction.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type4Fraction.getValueListArrayValues);
    }
}
