import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type3Number } from "./Type3Number";

@Component({
  selector: "spec-app-type3-number",
  templateUrl: "./type3Number.component.html",
  styleUrls: ["./type3Number.component.scss"]
})
export class Type3NumberComponent extends ObjectComponent implements OnInit {

  public Type3Number = Type3Number;
  @Input() public type3Number: Type3Number;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }

  convertNumber(convertable: any) {
    return parseInt(convertable.value, 10);
  }
}
