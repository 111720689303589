import { Injectable } from "@angular/core";
import { MenuItem, MenuService, ConfigService, CurrentUserContainer, PERMISSION_MENU, System } from "@xo/client-common";
import { SPECAPP } from "projects/specapp/src/specapp.service";
import { Type1StringType } from "../../../specapp/src/type1string/Type1String";
import { Type2LongStringType } from "../../../specapp/src/type2longstring/Type2LongString";
import { Type3NumberType } from "../../../specapp/src/type3number/Type3Number";
import { Type4FractionType } from "../../../specapp/src/type4fraction/Type4Fraction";
import { Type5BooleanType } from "../../../specapp/src/type5boolean/Type5Boolean";
import { Type6DateType } from "../../../specapp/src/type6date/Type6Date";
import { Type7TimeType } from "../../../specapp/src/type7time/Type7Time";
import { Type8TimestampType } from "../../../specapp/src/type8timestamp/Type8Timestamp";
import { Type9SpecialsType } from "../../../specapp/src/type9specials/Type9Specials";
import { Type12RichTextType } from "../../../specapp/src/type12richtext/Type12RichText";
import { Type1BaseType } from "../../../specapp/src/type1base/Type1Base";
import { Type2LinkType } from "../../../specapp/src/type2link/Type2Link";
import { Type11FileType } from "../../../specapp/src/type11file/Type11File";
import { Type10MixedType } from "../../../specapp/src/type10mixed/Type10Mixed";
import { Type13CodeType } from "../../../specapp/src/type13code/Type13Code";
import { UserDashboardType } from "projects/specapp/src/dashboard/user/UserDashboard";
import { AdminDashboardType } from "projects/specapp/src/dashboard/admin/AdminDashboard";
import { SystemDashboardType } from "projects/specapp/src/dashboard/system/SystemDashboard";

@Injectable()
export class SpecAppMenuService extends MenuService {

  constructor(configService: ConfigService, currentUserContainer: CurrentUserContainer) {
    super(configService, currentUserContainer);
  }

  async getModules(): Promise<MenuItem[]> {
    this.menu = [
      ...(await this.createCommonMenu()),
      ...(await this.createSpecAppMenus()),
      this.createProfileMenu()
    ].filter(menuItem => !!menuItem);
    return this.menu;
  }

  createSpecAppMenus() {
    return this.createModuleMenu(SPECAPP, async (multipleSystems: boolean, system: System) => {
      return [
        this.createMenu(
          (multipleSystems ? system.name + " / " : "") + "SpecApp",
          this.createMenuItem(system, SPECAPP, Type1StringType, PERMISSION_MENU,
            "Type1String", "SpecApp/Type1StringListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type2LongStringType, PERMISSION_MENU,
            "Type2LongString", "SpecApp/Type2LongStringListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type3NumberType, PERMISSION_MENU,
            "Type3Number", "SpecApp/Type3NumberListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type4FractionType, PERMISSION_MENU,
            "Type4Fraction", "SpecApp/Type4FractionListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type5BooleanType, PERMISSION_MENU,
            "Type5Boolean", "SpecApp/Type5BooleanListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type6DateType, PERMISSION_MENU,
            "Type6Date", "SpecApp/Type6DateListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type7TimeType, PERMISSION_MENU,
            "Type7Time", "SpecApp/Type7TimeListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type8TimestampType, PERMISSION_MENU,
            "Type8Timestamp", "SpecApp/Type8TimestampListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type9SpecialsType, PERMISSION_MENU,
            "Type9Specials", "SpecApp/Type9SpecialsListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type12RichTextType, PERMISSION_MENU,
            "Type12RichText", "SpecApp/Type12RichTextListWindowComponent"),
        ),
        this.createMenu(
          (multipleSystems ? system.name + " / " : "") + "SpecApp2",
          this.createMenuItem(system, SPECAPP, Type1BaseType, PERMISSION_MENU,
            "Type1Base", "SpecApp/Type1BaseListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type2LinkType, PERMISSION_MENU,
            "Type2Link", "SpecApp/Type2LinkListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type11FileType, PERMISSION_MENU,
            "Type11File", "SpecApp/Type11FileListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type10MixedType, PERMISSION_MENU,
            "Type10Mixed", "SpecApp/Type10MixedListWindowComponent"),
          this.createMenuItem(system, SPECAPP, Type13CodeType, PERMISSION_MENU,
            "Type13Code", "SpecApp/Type13CodeListWindowComponent"),
          this.createMenuItem(system, SPECAPP, UserDashboardType, PERMISSION_MENU, "User Dashboard", "SpecApp/UserDashBoardPage"),
          this.createMenuItem(system, SPECAPP, AdminDashboardType, PERMISSION_MENU, "Admin Dashboard", "SpecApp/AdminDashBoardPage"),
          this.createMenuItem(system, SPECAPP, SystemDashboardType, PERMISSION_MENU, "System Dashboard", "SpecApp/SystemDashBoardPage"),
        ),
      ];
    });
  }
}
