import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type13Code } from "./Type13Code";
import { ModalService } from "@xo/client-common";

@Component({
  selector: "spec-app-type13-code",
  templateUrl: "./type13Code.component.html",
  styleUrls: ["./type13Code.component.scss"]
})
export class Type13CodeComponent extends ObjectComponent implements OnInit {

  public Type13Code = Type13Code;
  @Input() public type13Code: Type13Code;
  @Input() public title: string;


  constructor(tab: TabComponent, public modalService: ModalService) {
    super(tab);
  }

  ngOnInit() {
  }

  async reloadTables() {
  }
}
