import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type4Fraction, Type4FractionType } from "./Type4Fraction";
import { Type4FractionService } from "./type4Fraction.service";
import { Type4FractionComponent } from "./type4Fraction.component";
import { createType4FractionValidators } from "./Type4FractionValidation";

@Component({
  selector: "spec-app-type4-fraction-page",
  templateUrl: "./type4Fraction-page.component.html",
  styleUrls: ["./type4Fraction-page.component.scss"]
})
export class Type4FractionPage extends ObjectWindow<Type4Fraction, Type4FractionService> {

  @ViewChild("component", { static: false }) component: Type4FractionComponent;

  Type4Fraction = Type4Fraction;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type4FractionType) as Type4FractionService, toastrService, createType4FractionValidators(), changeDetectorRef);
  }

  setupNewObject(rawType4Fraction) {
    let type4Fraction = Object.assign(new Type4Fraction(), rawType4Fraction);
    return type4Fraction;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
