import { createRequiredValidators, createRegexpValidator, createRegexpArrayValidator } from "@xo/client-common";
import { Type2LongString } from "./Type2LongString";

export function createType2LongStringValidators() {
    return [
        ...createRequiredValidators<Type2LongString>(["simpleField", "valueListField", "validatedField", "arrayField", "valueListArray", "validatedArrayField"]),
        createRegexpValidator('validatedField', /^[a-zA-Z]+$/, "Validated Field should contain only ^[a-zA-Z]+$!"),
        createRegexpArrayValidator('validatedArrayField', /^[a-zA-Z]+$/, "Validated Array Field should contain only ^[a-zA-Z]+$!"),
    ];
}
