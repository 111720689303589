import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type2LongString, Type2LongStringType } from "./Type2LongString";
import { Type2LongStringService } from "./type2LongString.service";
import { Type2LongStringComponent } from "./type2LongString.component";
import { createType2LongStringValidators } from "./Type2LongStringValidation";

@Component({
  selector: "spec-app-type2-long-string-page",
  templateUrl: "./type2LongString-page.component.html",
  styleUrls: ["./type2LongString-page.component.scss"]
})
export class Type2LongStringPage extends ObjectWindow<Type2LongString, Type2LongStringService> {

  @ViewChild("component", { static: false }) component: Type2LongStringComponent;

  Type2LongString = Type2LongString;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type2LongStringType) as Type2LongStringService, toastrService, createType2LongStringValidators(), changeDetectorRef);
  }

  setupNewObject(rawType2LongString) {
    let type2LongString = Object.assign(new Type2LongString(), rawType2LongString);
    return type2LongString;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
