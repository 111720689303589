import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent, ValidationMessage } from "@xo/client-common";
import { Type2Link } from "./Type2Link";
import { ModalService } from "@xo/client-common";
import { Type10Mixed, Type10MixedType } from "../type10mixed/Type10Mixed";
import { Type10MixedService } from "../type10mixed/type10Mixed.service";

@Component({
  selector: "spec-app-type2-link",
  templateUrl: "./type2Link.component.html",
  styleUrls: ["./type2Link.component.scss"]
})
export class Type2LinkComponent extends ObjectComponent implements OnInit {

  public Type2Link = Type2Link;
  @Input() public type2Link: Type2Link;
  @Input() public title: string;

  @Input() public mixedValidationMessages: ValidationMessage[];
  @Input() public mixedValidate;
  private type10MixedService: Type10MixedService;


  constructor(tab: TabComponent, public modalService: ModalService) {
    super(tab);
    this.type10MixedService = tab.getService(Type10MixedType);
  }

  ngOnInit() {
  }

  async reloadTables() {
  }

  saveMixed(mixed: Type10Mixed) {
    this.type10MixedService.save(mixed);
  }
}
