import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { transformDateToServer } from "@xo/services";
import { Type6Date, Type6DateType } from "./Type6Date";

@Component({
    selector: "spec-app-type6-date-select",
    templateUrl: "./type6Date.select.html",
    styleUrls: ["./type6Date.select.scss"]
})
export class Type6DateSelect extends SelectWindow<Type6Date> {

    constructor(modalService: ModalService) {
        super(modalService, Type6DateType, "simpleField");
    }

    formatLabel(label) {
        return transformDateToServer(label);
    }

}
