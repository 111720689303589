export let moduleExports = {
  "SpecApp": [
    "Type1String",
    "Type2LongString",
    "Type3Number",
    "Type4Fraction",
    "Type5Boolean",
    "Type6Date",
    "Type7Time",
    "Type8Timestamp",
    "Type9Specials",
    "Type12RichText",
    "Type1Base",
    "Type2Link",
    "Type11File",
    "Type10Mixed",
    "Type13Code",
  ],
};
