import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type1Base } from "./Type1Base";
import { ViewChild } from "@angular/core";
import { Type1BaseReference } from "../type1base/Type1Base";
import { PageService, FilterObject } from "@xo/client-common";
import { Type2LinkTable } from "../type2link/type2Link.table";
import { Type13CodeTable } from "../type13code/type13Code.table";

@Component({
  selector: "spec-app-type1-base",
  templateUrl: "./type1Base.component.html",
  styleUrls: ["./type1Base.component.scss"]
})
export class Type1BaseComponent extends ObjectComponent implements OnInit {

  public Type1Base = Type1Base;
  @Input() public type1Base: Type1Base;
  @Input() public title: string;

  @ViewChild("type2LinksWithSimpleField", { static: false }) type2LinksWithSimpleField: Type2LinkTable;
  type2LinkSimpleFieldFilter: FilterObject;

  @ViewChild("type2LinksWithValidatedField", { static: false }) type2LinksWithValidatedField: Type2LinkTable;
  type2LinkValidatedFieldFilter: FilterObject;

  @ViewChild("type2LinksWithArrayField", { static: false }) type2LinksWithArrayField: Type2LinkTable;
  type2LinkArrayFieldFilter: FilterObject;

  @ViewChild("type13CodesWithBase", { static: false }) type13CodesWithBase: Type13CodeTable;
  type13CodeBaseFilter: FilterObject;

  @ViewChild("type13CodesWithBaseArray", { static: false }) type13CodesWithBaseArray: Type13CodeTable;
  type13CodeBaseArrayFilter: FilterObject;


  constructor(tab: TabComponent, public pageService: PageService) {
    super(tab);
  }

  ngOnInit() {
    this.initFilters();
  }
  initFilters() {
    this.type2LinkSimpleFieldFilter = { simpleField: this.type1Base.id } as FilterObject;
    this.type2LinkValidatedFieldFilter = { validatedField: this.type1Base.id } as FilterObject;
    this.type2LinkArrayFieldFilter = { arrayField: this.type1Base.id } as FilterObject;
    this.type13CodeBaseFilter = { base: this.type1Base.id } as FilterObject;
    this.type13CodeBaseArrayFilter = { baseArray: this.type1Base.id } as FilterObject;
  }

  async reloadTables() {
    this.initFilters();
    await this.type2LinksWithSimpleField.refreshList();
    await this.type2LinksWithValidatedField.refreshList();
    await this.type2LinksWithArrayField.refreshList();
    await this.type13CodesWithBase.refreshList();
    await this.type13CodesWithBaseArray.refreshList();
  }

  createClickHandleForType2LinkSimpleField() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          simpleField: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType2LinkValidatedField() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          validatedField: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType2LinkArrayField() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          arrayField: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType13CodeBase() {
    return this.pageService.createOpenObject(
      "SpecApp/Type13CodePage",
      this.tab.system,
      (object) => `Type13Code at ID:${object ? object.id : null}`,
      () => {
        return {
          base: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }

  createClickHandleForType13CodeBaseArray() {
    return this.pageService.createOpenObject(
      "SpecApp/Type13CodePage",
      this.tab.system,
      (object) => `Type13Code at ID:${object ? object.id : null}`,
      () => {
        return {
          baseArray: new Type1BaseReference(this.type1Base.id, this.type1Base.name)
        };
      }
    );
  }
}
