import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ListWindow, PageService, TabComponent } from "@xo/client-common";
import { Type12RichTextTable } from "./type12RichText.table";

@Component({
  selector: "spec-app-type12-rich-text-list-window",
  templateUrl: "./type12RichText.list.html",
  styleUrls: ["./type12RichText.list.scss"]
})
export class Type12RichTextListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type12RichTextTable;

  clickHandler: Function;

  constructor(changeDetectorRef: ChangeDetectorRef, private tab: TabComponent, public pageService: PageService) {
    super(changeDetectorRef);
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type12RichTextPage",
      this.tab.system,
      (object) => `Type12RichText at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters, this.table);
  }

  reload() {
    console.log("Type12RichTextList reload");
    super.reload();
    this.table.refreshList();
  }
}
