import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type5Boolean } from "./Type5Boolean";

@Component({
  selector: "spec-app-type5-boolean",
  templateUrl: "./type5Boolean.component.html",
  styleUrls: ["./type5Boolean.component.scss"]
})
export class Type5BooleanComponent extends ObjectComponent implements OnInit {

  public Type5Boolean = Type5Boolean;
  @Input() public type5Boolean: Type5Boolean;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }
}
