import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type2LongString } from "./Type2LongString";

@Component({
  selector: "spec-app-type2-long-string",
  templateUrl: "./type2LongString.component.html",
  styleUrls: ["./type2LongString.component.scss"]
})
export class Type2LongStringComponent extends ObjectComponent implements OnInit {

  public Type2LongString = Type2LongString;
  @Input() public type2LongString: Type2LongString;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }
}
