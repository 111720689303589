import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type10Mixed, Type10MixedType } from "./Type10Mixed";

@Component({
    selector: "spec-app-type10-mixed-select",
    templateUrl: "./type10Mixed.select.html",
    styleUrls: ["./type10Mixed.select.scss"]
})
export class Type10MixedSelect extends SelectWindow<Type10Mixed> {

    constructor(modalService: ModalService) {
        super(modalService, Type10MixedType, "stringField");
    }
}
