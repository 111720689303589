import { Type9Specials, Type9SpecialsType } from "./Type9Specials";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type9-specials-table",
    styleUrls: ["./type9Specials.table.scss"],
    templateUrl: "./type9Specials.table.html"
})
export class Type9SpecialsTable extends PagingTableComponent<Type9Specials> implements OnInit {

    @Input() title: string;

    Type9Specials = Type9Specials;


    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type9SpecialsType, Type9Specials.columnInfo, modalTab || tab);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type9Specials) {
        let classes: any = { objects: true };
        return classes;
    }
}
