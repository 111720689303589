import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ListWindow, PageService, TabComponent } from "@xo/client-common";
import { Type5BooleanTable } from "./type5Boolean.table";

@Component({
  selector: "spec-app-type5-boolean-list-window",
  templateUrl: "./type5Boolean.list.html",
  styleUrls: ["./type5Boolean.list.scss"]
})
export class Type5BooleanListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type5BooleanTable;

  clickHandler: Function;

  constructor(changeDetectorRef: ChangeDetectorRef, private tab: TabComponent, public pageService: PageService) {
    super(changeDetectorRef);
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type5BooleanPage",
      this.tab.system,
      (object) => `Type5Boolean at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters, this.table);
  }

  reload() {
    console.log("Type5BooleanList reload");
    super.reload();
    this.table.refreshList();
  }
}
