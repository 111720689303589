import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type3Number, Type3NumberType } from "./Type3Number";
import { Type3NumberService } from "./type3Number.service";
import { Type3NumberComponent } from "./type3Number.component";
import { createType3NumberValidators } from "./Type3NumberValidation";

@Component({
  selector: "spec-app-type3-number-page",
  templateUrl: "./type3Number-page.component.html",
  styleUrls: ["./type3Number-page.component.scss"]
})
export class Type3NumberPage extends ObjectWindow<Type3Number, Type3NumberService> {

  @ViewChild("component", { static: false }) component: Type3NumberComponent;

  Type3Number = Type3Number;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type3NumberType) as Type3NumberService, toastrService, createType3NumberValidators(), changeDetectorRef);
  }

  setupNewObject(rawType3Number) {
    let type3Number = Object.assign(new Type3Number(), rawType3Number);
    return type3Number;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
