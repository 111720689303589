import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type9Specials, Type9SpecialsType } from "./Type9Specials";

@Component({
    selector: "spec-app-type9-specials-select",
    templateUrl: "./type9Specials.select.html",
    styleUrls: ["./type9Specials.select.scss"]
})
export class Type9SpecialsSelect extends SelectWindow<Type9Specials> {

    constructor(modalService: ModalService) {
        super(modalService, Type9SpecialsType, "emailField");
    }
}
