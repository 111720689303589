import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { checkValidationMessages, createValidate, ObjectWindow, TabComponent, ValidationMessage } from "@xo/client-common";
import { Type2Link, Type2LinkType } from "./Type2Link";
import { Type2LinkService } from "./type2Link.service";
import { Type2LinkComponent } from "./type2Link.component";
import { createType2LinkValidators } from "./Type2LinkValidation";
import { ObjectReference } from "@xo/services";
import { Type10Mixed, Type10MixedType } from "../type10mixed/Type10Mixed";
import { Type10MixedService } from "../type10mixed/type10Mixed.service";
import { createType10MixedValidators } from "../type10mixed/Type10MixedValidation";

@Component({
  selector: "spec-app-type2-link-page",
  templateUrl: "./type2Link-page.component.html",
  styleUrls: ["./type2Link-page.component.scss"]
})
export class Type2LinkPage extends ObjectWindow<Type2Link, Type2LinkService> {

  @ViewChild("component", { static: false }) component: Type2LinkComponent;

  Type2Link = Type2Link;

  public newArrayField: ObjectReference;

  public mixedValidationMessages: ValidationMessage[] = [];
  public mixedValidate = createValidate<Type10Mixed>(createType10MixedValidators(), this.mixedValidationMessages);
  type10MixedService: Type10MixedService;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type2LinkType) as Type2LinkService, toastrService, createType2LinkValidators(), changeDetectorRef);
    this.type10MixedService = tab.getService(Type10MixedType);
  }

  setupNewObject(rawType2Link) {
    let type2Link = Object.assign(new Type2Link(), rawType2Link);
    return type2Link;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }

  async save(object: Type2Link, validate: boolean = true, sendToServer?: boolean, closeOnSave?: boolean): Promise<Type2Link> {
    console.log("saving", JSON.stringify(object));
    if (validate) {
      await this.validate(object, undefined);
      await this.mixedValidate(object.mixed, undefined);
    }
    console.log(this.validationMessages, this.mixedValidationMessages)
    if (!checkValidationMessages(this.validationMessages, this.toastr)) {
      return null;
    }
    if (!checkValidationMessages(this.mixedValidationMessages, this.toastr)) {
      return null;
    }

    object.mixed = await this.type10MixedService.save(object.mixed);
    let type2Link = await super.save(object, validate, sendToServer, closeOnSave);
    console.log("saved", JSON.stringify(object), JSON.stringify(type2Link));
    return type2Link;
  }
}
