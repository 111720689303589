import { ObjectReference, Node, ColumnInfo } from "@xo/services";

export const valueListFieldValues = new Map<Date, string>();
valueListFieldValues.set(new Date("2018-01-01 00:01"), "First of January");
valueListFieldValues.set(new Date("2018-12-31 23:59"), "Silvester");
valueListFieldValues.set(new Date("2018-06-15 12:30"), "Mid June");

export const valueListArrayValues = new Map<Date, string>();
valueListArrayValues.set(new Date("2018-01-01 00:01"), "First of January");
valueListArrayValues.set(new Date("2018-12-31 23:59"), "Silvester");
valueListArrayValues.set(new Date("2018-06-15 12:30"), "Mid June");


export class Type8TimestampReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type8TimestampType = "Type8Timestamp";

export class Type8Timestamp extends Node {

    constructor() {
        super(Type8TimestampType, "SpecApp/SimpleType8TimestampComponent");
    }

    get label() {
        return !!this.simpleField ? this.simpleField.toISOString().substr(0, 16) : "";
    }

    set label(label) {
        this.simpleField = new Date(label);
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Simple Field", "simpleField", "Date", "None"),
        new ColumnInfo("ValueList Field", "valueListField", "DateSelect", "None"),
        new ColumnInfo("Validated Field", "validatedField", "Date", "None"),
        new ColumnInfo("Array Field", "arrayField", "Date", "None"),
        new ColumnInfo("ValueList Array", "valueListArray", "DateSelect", "None"),
        new ColumnInfo("Validated Array Field", "validatedArrayField", "Date", "None"),
    ];

    public static valueListFieldKeys: Date[] = Array.from(valueListFieldValues.keys());
    public static getValueListFieldValues(key: Date): string {
        return valueListFieldValues.get(key);
    }

    public static valueListArrayKeys: Date[] = Array.from(valueListArrayValues.keys());
    public static getValueListArrayValues(key: Date): string {
        return valueListArrayValues.get(key);
    }

    public id: number;
    public simpleField: Date;
    public valueListField: Date;
    public validatedField: Date;
    public arrayField: Date[] = [];
    public valueListArray: Date[] = [];
    public validatedArrayField: Date[] = [];
}
