import { Type3Number, Type3NumberType } from "./Type3Number";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type3-number-table",
    styleUrls: ["./type3Number.table.scss"],
    templateUrl: "./type3Number.table.html"
})
export class Type3NumberTable extends PagingTableComponent<Type3Number> implements OnInit {

    @Input() title: string;

    Type3Number = Type3Number;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type3NumberType, Type3Number.columnInfo, modalTab || tab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type3Number.valueListFieldKeys, Type3Number.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type3Number.valueListArrayKeys, Type3Number.getValueListArrayValues);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type3Number) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type3Number.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type3Number.getValueListArrayValues);
    }
}
