import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type6Date, Type6DateType } from "./Type6Date";
import { Type6DateService } from "./type6Date.service";
import { Type6DateComponent } from "./type6Date.component";
import { createType6DateValidators } from "./Type6DateValidation";

@Component({
  selector: "spec-app-type6-date-page",
  templateUrl: "./type6Date-page.component.html",
  styleUrls: ["./type6Date-page.component.scss"]
})
export class Type6DatePage extends ObjectWindow<Type6Date, Type6DateService> {

  @ViewChild("component", { static: false }) component: Type6DateComponent;

  Type6Date = Type6Date;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type6DateType) as Type6DateService, toastrService, createType6DateValidators(), changeDetectorRef);
  }

  setupNewObject(rawType6Date) {
    let type6Date = Object.assign(new Type6Date(), rawType6Date);
    return type6Date;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
