import { Type6Date, Type6DateType } from "./Type6Date";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";
import { DatePipe } from "@angular/common";

@Component({
    selector: "spec-app-type6-date-table",
    styleUrls: ["./type6Date.table.scss"],
    templateUrl: "./type6Date.table.html"
})
export class Type6DateTable extends PagingTableComponent<Type6Date> implements OnInit {

    @Input() title: string;

    Type6Date = Type6Date;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        private datePipe: DatePipe,
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type6DateType, Type6Date.columnInfo, modalTab || tab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type6Date.valueListFieldKeys, Type6Date.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type6Date.valueListArrayKeys, Type6Date.getValueListArrayValues);
    }

    async ngOnInit() {
        this.refreshList();
    }

    simpleFieldFilterChanged(event) {
        if (event.value) {
            this.filters["simpleField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["simpleField"];
        }
        this.reload();
    }

    valueListFieldFilterChanged(event) {
        if (event.value) {
            this.filters["valueListField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["valueListField"];
        }
        this.reload();
    }

    validatedFieldFilterChanged(event) {
        if (event.value) {
            this.filters["validatedField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["validatedField"];
        }
        this.reload();
    }

    arrayFieldFilterChanged(event) {
        if (event.value) {
            this.filters["arrayField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["arrayField"];
        }
        this.reload();
    }

    valueListArrayFilterChanged(event) {
        if (event.value) {
            this.filters["valueListArray"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["valueListArray"];
        }
        this.reload();
    }

    validatedArrayFieldFilterChanged(event) {
        if (event.value) {
            this.filters["validatedArrayField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["validatedArrayField"];
        }
        this.reload();
    }

    getClasses(type6Date) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type6Date.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type6Date.getValueListArrayValues);
    }
}
