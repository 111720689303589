import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ListWindow, PageService, TabComponent } from "@xo/client-common";
import { Type1BaseTable } from "./type1Base.table";

@Component({
  selector: "spec-app-type1-base-list-window",
  templateUrl: "./type1Base.list.html",
  styleUrls: ["./type1Base.list.scss"]
})
export class Type1BaseListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type1BaseTable;

  clickHandler: Function;

  constructor(changeDetectorRef: ChangeDetectorRef, private tab: TabComponent, public pageService: PageService) {
    super(changeDetectorRef);
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type1BasePage",
      this.tab.system,
      (object) => `Type1Base at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters, this.table);
  }

  reload() {
    console.log("Type1BaseList reload");
    super.reload();
    this.table.refreshList();
  }
}
