import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type5Boolean, Type5BooleanType } from "./Type5Boolean";
import { Type5BooleanService } from "./type5Boolean.service";
import { Type5BooleanComponent } from "./type5Boolean.component";
import { createType5BooleanValidators } from "./Type5BooleanValidation";

@Component({
  selector: "spec-app-type5-boolean-page",
  templateUrl: "./type5Boolean-page.component.html",
  styleUrls: ["./type5Boolean-page.component.scss"]
})
export class Type5BooleanPage extends ObjectWindow<Type5Boolean, Type5BooleanService> {

  @ViewChild("component", { static: false }) component: Type5BooleanComponent;

  Type5Boolean = Type5Boolean;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type5BooleanType) as Type5BooleanService, toastrService, createType5BooleanValidators(), changeDetectorRef);
  }

  setupNewObject(rawType5Boolean) {
    let type5Boolean = Object.assign(new Type5Boolean(), rawType5Boolean);
    return type5Boolean;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
