import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type8Timestamp } from "./Type8Timestamp";

@Component({
  selector: "spec-app-type8-timestamp",
  templateUrl: "./type8Timestamp.component.html",
  styleUrls: ["./type8Timestamp.component.scss"]
})
export class Type8TimestampComponent extends ObjectComponent implements OnInit {

  public Type8Timestamp = Type8Timestamp;
  @Input() public type8Timestamp: Type8Timestamp;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }
}
