import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type1String } from "./Type1String";

@Component({
  selector: "spec-app-type1-string",
  templateUrl: "./type1String.component.html",
  styleUrls: ["./type1String.component.scss"]
})
export class Type1StringComponent extends ObjectComponent implements OnInit {

  public Type1String = Type1String;
  @Input() public type1String: Type1String;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }
}
