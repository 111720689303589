import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type12RichText, Type12RichTextType } from "./Type12RichText";
import { Type12RichTextService } from "./type12RichText.service";
import { Type12RichTextComponent } from "./type12RichText.component";
import { createType12RichTextValidators } from "./Type12RichTextValidation";

@Component({
  selector: "spec-app-type12-rich-text-page",
  templateUrl: "./type12RichText-page.component.html",
  styleUrls: ["./type12RichText-page.component.scss"]
})
export class Type12RichTextPage extends ObjectWindow<Type12RichText, Type12RichTextService> {

  @ViewChild("component", { static: false }) component: Type12RichTextComponent;

  Type12RichText = Type12RichText;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type12RichTextType) as Type12RichTextService, toastrService, createType12RichTextValidators(), changeDetectorRef);
  }

  setupNewObject(rawType12RichText) {
    let type12RichText = Object.assign(new Type12RichText(), rawType12RichText);
    return type12RichText;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
