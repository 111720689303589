import { Type10Mixed, Type10MixedType } from "./Type10Mixed";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";
import { DatePipe } from "@angular/common";

@Component({
    selector: "spec-app-type10-mixed-table",
    styleUrls: ["./type10Mixed.table.scss"],
    templateUrl: "./type10Mixed.table.html"
})
export class Type10MixedTable extends PagingTableComponent<Type10Mixed> implements OnInit {

    @Input() title: string;

    Type10Mixed = Type10Mixed;


    constructor(
        private datePipe: DatePipe,
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type10MixedType, Type10Mixed.columnInfo, modalTab || tab);
    }

    async ngOnInit() {
        this.refreshList();
    }

    dateFieldFilterChanged(event) {
        if (event.value) {
            this.filters["dateField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["dateField"];
        }
        this.reload();
    }

    dateArrayFieldFilterChanged(event) {
        if (event.value) {
            this.filters["dateArrayField"] = this.datePipe.transform(new Date(event.value), "yyyy-MM-dd");
        } else {
            delete this.filters["dateArrayField"];
        }
        this.reload();
    }

    getClasses(type10Mixed) {
        let classes: any = { objects: true };
        return classes;
    }

    formatBooleanField(booleanField) {
        return booleanField ? "Igen" : "Nem";
    }

    formatBooleanArrayField(booleanArrayField) {
        if (!booleanArrayField) {
            return "";
        }
        return booleanArrayField.map(v => v ? "Igen" : "Nem");
    }
}
