import { Type12RichText, Type12RichTextType } from "./Type12RichText";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type12-rich-text-table",
    styleUrls: ["./type12RichText.table.scss"],
    templateUrl: "./type12RichText.table.html"
})
export class Type12RichTextTable extends PagingTableComponent<Type12RichText> implements OnInit {

    @Input() title: string;

    Type12RichText = Type12RichText;


    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type12RichTextType, Type12RichText.columnInfo, modalTab || tab);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type12RichText) {
        let classes: any = { objects: true };
        return classes;
    }
}
