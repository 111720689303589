import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type7Time } from "./Type7Time";

@Component({
  selector: "spec-app-type7-time",
  templateUrl: "./type7Time.component.html",
  styleUrls: ["./type7Time.component.scss"]
})
export class Type7TimeComponent extends ObjectComponent implements OnInit {

  public Type7Time = Type7Time;
  @Input() public type7Time: Type7Time;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }
}
