import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type5Boolean, Type5BooleanType } from "./Type5Boolean";

@Component({
    selector: "spec-app-type5-boolean-select",
    templateUrl: "./type5Boolean.select.html",
    styleUrls: ["./type5Boolean.select.scss"]
})
export class Type5BooleanSelect extends SelectWindow<Type5Boolean> {

    constructor(modalService: ModalService) {
        super(modalService, Type5BooleanType, "simpleField");
    }
}
