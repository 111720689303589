import { Type7Time, Type7TimeType } from "./Type7Time";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type7-time-table",
    styleUrls: ["./type7Time.table.scss"],
    templateUrl: "./type7Time.table.html"
})
export class Type7TimeTable extends PagingTableComponent<Type7Time> implements OnInit {

    @Input() title: string;

    Type7Time = Type7Time;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type7TimeType, Type7Time.columnInfo, modalTab || tab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type7Time.valueListFieldKeys, Type7Time.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type7Time.valueListArrayKeys, Type7Time.getValueListArrayValues);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type7Time) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type7Time.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type7Time.getValueListArrayValues);
    }
}
