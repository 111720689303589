import { Type1Base, Type1BaseType } from "./Type1Base";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";
import { Type1BaseService } from "./type1Base.service";

@Component({
    selector: "spec-app-type1-base-table",
    styleUrls: ["./type1Base.table.scss"],
    templateUrl: "./type1Base.table.html"
})
export class Type1BaseTable extends PagingTableComponent<Type1Base> implements OnInit {

    @Input() title: string;

    Type1Base = Type1Base;


    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type1BaseType, Type1Base.columnInfo, modalTab || tab);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type1Base) {
        let classes: any = { objects: true };
        return classes;
    }

    async action() {
        await (this.tab.getService(Type1BaseType) as Type1BaseService).action();
    }
}
