import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type1String, Type1StringType } from "./Type1String";
import { Type1StringService } from "./type1String.service";
import { Type1StringComponent } from "./type1String.component";
import { createType1StringValidators } from "./Type1StringValidation";

@Component({
  selector: "spec-app-type1-string-page",
  templateUrl: "./type1String-page.component.html",
  styleUrls: ["./type1String-page.component.scss"]
})
export class Type1StringPage extends ObjectWindow<Type1String, Type1StringService> {

  @ViewChild("component", { static: false }) component: Type1StringComponent;

  Type1String = Type1String;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type1StringType) as Type1StringService, toastrService, createType1StringValidators(), changeDetectorRef);
  }

  setupNewObject(rawType1String) {
    let type1String = Object.assign(new Type1String(), rawType1String);
    return type1String;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
