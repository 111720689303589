import { ObjectReference, Node, ColumnInfo } from "@xo/services";


export class Type11FileReference extends ObjectReference {
    constructor(id?: string, label?: string) {
        super(id, label, "code");
    }
}

export let Type11FileType = "Type11File";

export class Type11File extends Node {

    constructor() {
        super(Type11FileType, "SpecApp/SimpleType11FileComponent");
    }

    get id() {
        return this.code;
    }

    set id(id) {
        this.code = id;
    }

    get label() {
        return "" + this.simpleFile;
    }

    set label(label) {
        this.simpleFile = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Code", "code", "string", "None"),
        new ColumnInfo("Simple File", "simpleFile", "File", "None"),
        new ColumnInfo("Validated File", "validatedFile", "File", "None"),
    ];

    public code: string;
    public simpleFile: File | string;
    public validatedFile: File | string;
}
