import { createRequiredValidators, createRegexpValidator, createRegexpArrayValidator, Error, Validator } from "@xo/client-common";
import { Type1String } from "./Type1String";

export function createType1StringValidators() {
    return [
        alma(),
        ...createRequiredValidators<Type1String>(["simpleField", "valueListField", "validatedField", "arrayField", "valueListArray", "validatedArrayField"]),
        createRegexpValidator('validatedField', /[a-zA-Z]+/, "Validated Field should contain only [a-zA-Z]+!"),
        createRegexpArrayValidator('validatedArrayField', /[a-zA-Z]+/, "Validated Array Field should contain only [a-zA-Z]+!"),
    ];
}

function alma() {
    return <Validator<Type1String>>{
        fieldName: null,
        validate: (object) => {
            if (object.simpleField == "alma") {
                return [new Error(null, "alma error")];
            } else {
                return [];
            }
        }
    };
}
