import { Type5Boolean, Type5BooleanType } from "./Type5Boolean";
import { Component, Input, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, TabComponent, ModalTabComponent } from "@xo/client-common";

@Component({
    selector: "spec-app-type5-boolean-table",
    styleUrls: ["./type5Boolean.table.scss"],
    templateUrl: "./type5Boolean.table.html"
})
export class Type5BooleanTable extends PagingTableComponent<Type5Boolean> implements OnInit {

    @Input() title: string;

    Type5Boolean = Type5Boolean;


    valueListFieldSelectValues = [];

    valueListArraySelectValues = [];

    constructor(
        @Optional() modalTab: ModalTabComponent, @Optional() tab: TabComponent) {
        super(Type5BooleanType, Type5Boolean.columnInfo, modalTab || tab);
        this.valueListFieldSelectValues = this.createReferenceMap(Type5Boolean.valueListFieldKeys, Type5Boolean.getValueListFieldValues);
        this.valueListArraySelectValues = this.createReferenceMap(Type5Boolean.valueListArrayKeys, Type5Boolean.getValueListArrayValues);
    }

    async ngOnInit() {
        this.refreshList();
    }

    getClasses(type5Boolean) {
        let classes: any = { objects: true };
        return classes;
    }

    formatValueListField(valueListField) {
        return Type5Boolean.getValueListFieldValues(valueListField);
    }

    formatValueListArray(valueListArray) {
        if (!valueListArray) {
            return "";
        }
        return valueListArray.map(Type5Boolean.getValueListArrayValues);
    }

    formatSimpleField(simpleField) {
        return simpleField ? "Igen" : "Nem";
    }

    formatValidatedField(validatedField) {
        return validatedField ? "Igen" : "Nem";
    }

    formatArrayField(arrayField) {
        if (!arrayField) {
            return "";
        }
        return arrayField.map(v => v ? "Igen" : "Nem");
    }

    formatValidatedArrayField(validatedArrayField) {
        if (!validatedArrayField) {
            return "";
        }
        return validatedArrayField.map(v => v ? "Igen" : "Nem");
    }
}
