import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent } from "@xo/client-common";
import { Type7Time, Type7TimeType } from "./Type7Time";
import { Type7TimeService } from "./type7Time.service";
import { Type7TimeComponent } from "./type7Time.component";
import { createType7TimeValidators } from "./Type7TimeValidation";

@Component({
  selector: "spec-app-type7-time-page",
  templateUrl: "./type7Time-page.component.html",
  styleUrls: ["./type7Time-page.component.scss"]
})
export class Type7TimePage extends ObjectWindow<Type7Time, Type7TimeService> {

  @ViewChild("component", { static: false }) component: Type7TimeComponent;

  Type7Time = Type7Time;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    tab: TabComponent) {

    super(tab.getService(Type7TimeType) as Type7TimeService, toastrService, createType7TimeValidators(), changeDetectorRef);
  }

  setupNewObject(rawType7Time) {
    let type7Time = Object.assign(new Type7Time(), rawType7Time);
    return type7Time;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
