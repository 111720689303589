import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ListWindow, PageService, TabComponent } from "@xo/client-common";
import { Type6DateTable } from "./type6Date.table";

@Component({
  selector: "spec-app-type6-date-list-window",
  templateUrl: "./type6Date.list.html",
  styleUrls: ["./type6Date.list.scss"]
})
export class Type6DateListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type6DateTable;

  clickHandler: Function;

  constructor(changeDetectorRef: ChangeDetectorRef, private tab: TabComponent, public pageService: PageService) {
    super(changeDetectorRef);
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type6DatePage",
      this.tab.system,
      (object) => `Type6Date at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters, this.table);
  }

  reload() {
    console.log("Type6DateList reload");
    super.reload();
    this.table.refreshList();
  }
}
