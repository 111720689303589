import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type12RichText, Type12RichTextType } from "./Type12RichText";

@Component({
    selector: "spec-app-type12-rich-text-select",
    templateUrl: "./type12RichText.select.html",
    styleUrls: ["./type12RichText.select.scss"]
})
export class Type12RichTextSelect extends SelectWindow<Type12RichText> {

    constructor(modalService: ModalService) {
        super(modalService, Type12RichTextType, "simpleField");
    }
}
