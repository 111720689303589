import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ListWindow, PageService, TabComponent } from "@xo/client-common";
import { Type10MixedTable } from "./type10Mixed.table";

@Component({
  selector: "spec-app-type10-mixed-list-window",
  templateUrl: "./type10Mixed.list.html",
  styleUrls: ["./type10Mixed.list.scss"]
})
export class Type10MixedListWindowComponent extends ListWindow {

  @ViewChild("table", { static: false }) table: Type10MixedTable;

  clickHandler: Function;

  constructor(changeDetectorRef: ChangeDetectorRef, private tab: TabComponent, public pageService: PageService) {
    super(changeDetectorRef);
  }

  init(filters?) {
    this.clickHandler = this.pageService.createOpenObject(
      "SpecApp/Type10MixedPage",
      this.tab.system,
      (object) => `Type10Mixed at ID:${object ? object.id : null}`,
      () => ({})
    );
    super.init(filters, this.table);
  }

  reload() {
    console.log("Type10MixedList reload");
    super.reload();
    this.table.refreshList();
  }
}
