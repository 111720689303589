import { Component, Input, OnInit } from "@angular/core";
import { ObjectComponent, TabComponent } from "@xo/client-common";
import { Type4Fraction } from "./Type4Fraction";

@Component({
  selector: "spec-app-type4-fraction",
  templateUrl: "./type4Fraction.component.html",
  styleUrls: ["./type4Fraction.component.scss"]
})
export class Type4FractionComponent extends ObjectComponent implements OnInit {

  public Type4Fraction = Type4Fraction;
  @Input() public type4Fraction: Type4Fraction;
  @Input() public title: string;


  constructor(tab: TabComponent) {
    super(tab);
  }

  ngOnInit() {
  }

  convertFraction(convertable: any) {
    return parseFloat(convertable.value);
  }
}
