import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Type4Fraction, Type4FractionType } from "./Type4Fraction";

@Component({
    selector: "spec-app-type4-fraction-select",
    templateUrl: "./type4Fraction.select.html",
    styleUrls: ["./type4Fraction.select.scss"]
})
export class Type4FractionSelect extends SelectWindow<Type4Fraction> {

    constructor(modalService: ModalService) {
        super(modalService, Type4FractionType, "simpleField");
    }
}
